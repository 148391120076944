import { createMuiTheme } from "@material-ui/core/styles";
// import yellow from '@material-ui/core/colors/yellow';
import green from "@material-ui/core/colors/green";

const AppTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[600],
    },
    secondary: {
      main: "#ffea00", // yellow[A400]
    },
  },
});

export default AppTheme;
