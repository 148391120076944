import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Link from "@material-ui/core/Link";
import UserProfileMenu from "../UserProfileMenu/component";
import NavDrawer from "../NavDrawer/component";
import { currentUserVar } from "../../cache";
import { useReactiveVar } from "@apollo/client";

import styles from "./styles.module.scss";

const AppNavigation = () => {
  const { loginWithRedirect } = useAuth0();
  const [drawer, setDrawer] = useState(false);
  const currentUser = useReactiveVar(currentUserVar);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  return (
    <div className={styles.root}>
      <AppBar position="static">
        <Toolbar>
          {currentUser && (
            <IconButton
              data-testid="main-menu-icon"
              edge="start"
              className={styles.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={styles.title}>
            <Link component={RouterNavLink} to="/" color="inherit">
              Bay Tree Learning
            </Link>
          </Typography>
          {!currentUser && (
            <Button
              data-testid="login-button"
              color="inherit"
              onClick={() => loginWithRedirect()}
            >
              Login
            </Button>
          )}
          {currentUser && <UserProfileMenu />}
        </Toolbar>
      </AppBar>
      <NavDrawer open={drawer} toggleDrawer={toggleDrawer} />
    </div>
  );
};

export default AppNavigation;
