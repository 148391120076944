import { LogoutOptions } from "@auth0/auth0-spa-js";

export const logoutWithRedirect = (
  logout: (options?: LogoutOptions | undefined) => void
) => {
  // @ts-ignore
  if (window.Cypress) {
    localStorage.removeItem("auth0Cypress");
  }
  logout({
    returnTo: window.location.origin,
  });
};
