import React from "react";
import get from "just-safe-get";

import { gql, useQuery } from "@apollo/client";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

import {
  getMetricValues as getMetricValuesData,
  getMetricValues_metrics_byId as Metric,
} from "./__generated__/getMetricValues";

import styles from "./styles.module.scss";

export const GET_METRIC_VALUES = gql`
  query getMetricValues($metricId: ID!) {
    metrics {
      byId(id: $metricId) {
        id
        values {
          id
          slug
          label
          description
          unitDescription
          required
        }
      }
    }
  }
`;

type MeasurementValFldsProps = {
  metricId: string;
  measurementValues: any;
  setMeasurementValues: (values: any) => void;
};

export const MeasurementValuesFields = (props: MeasurementValFldsProps) => {
  const { loading, error, data } = useQuery<getMetricValuesData>(
    GET_METRIC_VALUES,
    {
      variables: { metricId: props.metricId },
    }
  );

  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setMeasurementValues({
      ...props.measurementValues,
      [key]: parseInt(event.target.value),
    });
  };

  if (loading) return <div data-testid="mvfields-loading">Loading...</div>;
  if (error) return <div>Error! {error.message}</div>;

  const metric: Metric = get(data || {}, ["metrics", "byId"]);

  return (
    <div data-testid="mvfields">
      <Typography variant="h6">Measurement Values</Typography>
      <div className={styles.values}>
        {metric.values.map((value) => {
          let key = `${value?.slug}`;
          return (
            <TextField
              id={key}
              data-testid="measurement-value-field"
              key={key}
              value={props.measurementValues[key] || ""}
              onChange={(e) => handleChange(key, e)}
              required={value?.required === true}
              label={value?.label}
              helperText={value?.description}
              className={styles.valueField}
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip
                      placement="left"
                      title={value?.unitDescription || ""}
                      arrow
                    >
                      <InfoOutlined />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MeasurementValuesFields;
