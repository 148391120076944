import React from "react";
import get from "just-safe-get";

import { gql, useQuery } from "@apollo/client";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getMetrics as getMetricsData,
  getMetrics_metrics_connection_edges as MetricEdge,
} from "./__generated__/getMetrics";

import styles from "./styles.module.scss";

export const GET_METRICS = gql`
  query getMetrics {
    metrics {
      connection(first: 30, sortBy: [{ sort: NAME, direction: ASC }]) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            internalName
            level
            skill {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          startCursor
        }
      }
    }
  }
`;

type MetricSelectProps = {
  metricId: string;
  setMetricId: (id: string) => void;
};

export const MetricSelect = (props: MetricSelectProps) => {
  const { loading, error, data } = useQuery<getMetricsData>(GET_METRICS);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.setMetricId(event.target.value as string);
  };

  if (loading)
    return (
      <CircularProgress data-testid="metric-select-loading" color="primary" />
    );
  if (error) return <div>Error! {error.message}</div>;

  const metricEdges: MetricEdge[] =
    get(data || {}, ["metrics", "connection", "edges"]) || [];

  return (
    <FormControl
      required
      variant="outlined"
      className={`metricSelectField ${styles.formControl}`}
    >
      <InputLabel id="select-metric-label">Metric</InputLabel>
      <Select
        labelId="select-metric-label"
        id="metric-select"
        data-testid="metric-select"
        value={props.metricId}
        onChange={handleChange}
        label="Metric"
        className={styles.select}
      >
        <MenuItem data-testid="metric-select-none" key={0} value="">
          <em>None</em>
        </MenuItem>
        {metricEdges.map((metricEdge, idx) => {
          let metric = metricEdge?.node;
          return (
            <MenuItem
              data-testid={`metric-select-option-${idx}`}
              key={metric?.id}
              value={metric?.id}
            >
              {metric?.internalName} ({metric?.skill?.name})
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>Required</FormHelperText>
    </FormControl>
  );
};

export default MetricSelect;
