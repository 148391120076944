import React, { useEffect } from "react";
import { ApolloClient, ApolloProvider, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import {
  cache,
  currentUserVar,
  isAuthenticatedVar,
  isAuthenticationLoadingVar,
  socialProfilePicVar,
} from "./cache";
import { useReactiveVar } from "@apollo/client";

//@ts-ignore
import runtimeEnv from "@mars/heroku-js-runtime-env";

type AuthorizedApolloProviderProps = {
  children: JSX.Element;
};

const AuthorizedApolloProvider = (props: AuthorizedApolloProviderProps) => {
  const {
    isAuthenticated,
    isLoading,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const currentUser = useReactiveVar(currentUserVar);

  // @ts-ignore
  if (window.Cypress) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const auth0 = JSON.parse(localStorage.getItem("auth0Cypress")!);
      if (auth0) {
        isAuthenticatedVar(true);
        isAuthenticationLoadingVar(false);
      } else {
        currentUserVar(undefined);
      }
    });
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      isAuthenticatedVar(isAuthenticated);
      isAuthenticationLoadingVar(isLoading);
      socialProfilePicVar(user?.picture);
      if (!isAuthenticated) {
        currentUserVar(undefined);
        socialProfilePicVar(undefined);
      }
    }, [isAuthenticated, isLoading, user, currentUser]);
  }

  const httpLink = createHttpLink({
    uri: `${runtimeEnv().REACT_APP_API_ORIGIN}/graphql`,
  });

  const authLink = setContext(async () => {
    let token = "";
    //@ts-ignore
    if (window.Cypress) {
      const auth0 = JSON.parse(localStorage.getItem("auth0Cypress")!);
      if (auth0) {
        token = auth0.body.access_token;
      }
    } else {
      token = await getAccessTokenSilently();
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: true,
    cache,
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};

export default AuthorizedApolloProvider;
