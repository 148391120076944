import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { useReactiveVar, gql, useQuery } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Page from "./components/Page/component";
import Loading from "./components/Loading";
import AppNavigation from "./components/AppNavigation/component";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import TestMeasurements from "./views/TestMeasurements";
import { history } from "./utils/historyUtils";
import {
  isAuthenticationLoadingVar,
  isAuthenticatedVar,
  currentUserVar,
} from "./cache";
import { getMyUser as getMyUserData } from "./__generated__/getMyUser";

import styles from "./App.module.scss";

const SiteTitle = "Bay Tree Portal";

export const GET_MY_USER = gql`
  query getMyUser {
    users {
      me {
        first
        last
        email
        roles
      }
    }
  }
`;

const App = () => {
  const isAuthenticated = useReactiveVar(isAuthenticatedVar);

  const { error, data } = useQuery<getMyUserData>(GET_MY_USER, {
    skip: !isAuthenticated,
  });
  // Set the global current user when this query returns:
  useEffect(() => {
    currentUserVar(data?.users?.me);
  }, [data?.users?.me]);

  const isAuthenticationLoading = useReactiveVar(isAuthenticationLoadingVar);
  const currentUser = useReactiveVar(currentUserVar);

  if (error) {
    console.log(`Error retreiving current user from API: ${error.message}`);

    return <div>{`Error logging in: ${error.message}`}</div>;
  }

  // Show <Loading /> screen if authenticating or loading current user from API:
  if (isAuthenticationLoading || (isAuthenticated && currentUser == null)) {
    return <Loading />;
  }

  const pageTitle = (pageName: String) => `${SiteTitle} - ${pageName}`;

  return (
    <Router history={history}>
      <div id="app" className={`d-flex flex-column h-100 ${styles.App}`}>
        <AppNavigation />
        <Paper className={styles.paper} elevation={3}>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Page title={pageTitle("Home")}>
                  <Home />
                </Page>
              )}
            />
            <Route
              path="/profile"
              render={(props) => (
                <Page title={pageTitle("Profile")}>
                  <Profile {...props} />
                </Page>
              )}
            />
            <Route
              path="/test-measurements"
              render={(props) => (
                <Page title={pageTitle("Test Measurements")}>
                  <TestMeasurements {...props} />
                </Page>
              )}
            />
          </Switch>
        </Paper>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
