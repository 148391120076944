import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutWithRedirect } from "../../utils/authUtils";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import { currentUserVar, socialProfilePicVar } from "../../cache";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../utils/userUtils";

const UserProfileMenu = () => {
  const { logout } = useAuth0();

  const currentUser = useReactiveVar(currentUserVar);
  const authProfilePic = useReactiveVar(socialProfilePicVar);

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );

  const handleAvatarClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Avatar
        data-testid="user-avatar"
        alt="Profile"
        src={authProfilePic}
        onClick={handleAvatarClick}
      />
      <Menu
        data-testid="user-profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
      >
        {isAdmin(currentUser) && (
          <MenuItem
            data-testid="user-profile-menu-profile-item"
            component={RouterNavLink}
            to="/profile"
            onClick={handleProfileMenuClose}
          >
            Profile
          </MenuItem>
        )}
        <MenuItem
          data-testid="logout-menu-item"
          onClick={() => logoutWithRedirect(logout)}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfileMenu;
