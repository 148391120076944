import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { currentUserVar } from "../../cache";
import { useReactiveVar } from "@apollo/client";
import { isAdmin, isTeacher } from "../../utils/userUtils";

import styles from "./styles.module.scss";

type NavDrawerProps = {
  open: boolean;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const NavDrawer = (props: NavDrawerProps) => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <Drawer
      className={styles.drawer}
      data-testid="nav-drawer"
      anchor="left"
      open={props.open}
      onClose={props.toggleDrawer(false)}
      classes={{
        paper: styles.drawer,
      }}
    >
      <div
        className={styles.list}
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
      >
        <List>
          {(isAdmin(currentUser) || isTeacher(currentUser)) && (
            <ListItem
              data-testid="test-measurements-main-menu-item"
              button
              key="Test Measurements"
              component={RouterNavLink}
              to="/test-measurements"
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Test Measurements" />
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
};

export default NavDrawer;
