import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { history } from "./utils/historyUtils";
import { Auth0Provider } from "@auth0/auth0-react";
import AppTheme from "./AppTheme";
import { ThemeProvider } from "@material-ui/core";
import AuthorizedApolloProvider from "./AuthorizedApolloProvider";
import App from "./App";

//@ts-ignore
import runtimeEnv from "@mars/heroku-js-runtime-env";

import "./index.scss";

const onRedirectCallback = (appState: any) => {
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const env = runtimeEnv();
const providerConfig = {
  domain: env.REACT_APP_AUTH0_DOMAIN || "",
  clientId: env.REACT_APP_AUTH0_CLIENT_ID || "",
  audience: env.REACT_APP_AUTH0_AUDIENCE || "btree_api",
  redirectUri: window.location.origin,
  scope: "email",
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <ThemeProvider theme={AppTheme}>
        <AuthorizedApolloProvider>
          <App />
        </AuthorizedApolloProvider>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
