import React from "react";

const Footer = () => (
  <footer>
    <div className="logo" />
    <p>Bay Tree Learning Solutions, Inc. © 2021. All rights reserved.</p>
  </footer>
);

export default Footer;
