import React from "react";
import { gql, useMutation } from "@apollo/client";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createMeasurement as createMeasurementData,
  createMeasurementVariables as createMeasurementVars,
} from "./__generated__/createMeasurement";
import { CreateMeasurementInput } from "./../../__generated__/global";

export const CREATE_MEASUREMENT = gql`
  mutation createMeasurement($createInput: CreateMeasurementInput!) {
    measurements {
      create(input: $createInput) {
        measurement {
          id
          values {
            key
            value
          }
          student {
            id
            first
            last
          }
          teacher {
            email
          }
          metric {
            slug
          }
        }
        errors
        clientMutationId
      }
    }
  }
`;

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type MeasurementCreateButtonProps = {
  disabled: boolean;
  measurementInput: CreateMeasurementInput | null;
  onSuccess: () => void;
};

export const MeasurementCreateButton = (
  props: MeasurementCreateButtonProps
) => {
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const [
    createMeasurement,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation<createMeasurementData, createMeasurementVars>(
    CREATE_MEASUREMENT,
    {
      onCompleted() {
        setSuccessOpen(true);
        props.onSuccess();
      },
      onError() {
        setErrorOpen(true);
      },
    }
  );

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const handleSubmit = () => {
    if (!props.measurementInput) {
      return;
    }
    createMeasurement({ variables: { createInput: props.measurementInput } });
  };

  if (mutationLoading)
    return (
      <CircularProgress
        data-testid="measurement-create-button-loading"
        color="primary"
      />
    );

  return (
    <React.Fragment>
      <Button
        data-testid="measurement-create-button"
        variant="outlined"
        color="primary"
        disabled={props.disabled}
        onClick={handleSubmit}
      >
        Create
      </Button>
      <Snackbar
        data-testid="measurement-create-button-success"
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Measurement successfully created.
        </Alert>
      </Snackbar>
      <Snackbar
        data-testid="measurement-create-button-fail"
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {mutationError?.message || "An error occurred"}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default MeasurementCreateButton;
