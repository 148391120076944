import { InMemoryCache, makeVar } from "@apollo/client";
import { getMyUser_users_me as CurrentUser } from "./__generated__/getMyUser";

export const cache: InMemoryCache = new InMemoryCache({});

// Is Authentication loading. This variable is a follower of Auth0's
// isLoading method.
export const isAuthenticationLoadingVar = makeVar<boolean>(false);

// Is Authenticated. This variable is a follower of Auth0's
// isAuthenticated method.
// FOR TESTING ONLY, this variable initializes to true if localStorage
// includes a 'auth0Cypress' key, false otherwise.
export const isAuthenticatedVar = makeVar<boolean>(
  // @ts-expect-error
  window.Cypress && !!localStorage.getItem("auth0Cypress")
);

// If user logs in with Google, we can use their profile picture. This variable
// is a follower of auth0's user.picture
export const socialProfilePicVar = makeVar<string | undefined>(undefined);

// This is the user object that comes from our API. CurrentUser type includes
// roles for authorization across the app...
// Initialize to null, but set undefined if API responds without a valid user
export const currentUserVar = makeVar<CurrentUser | undefined | null>(null);
