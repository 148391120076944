// Original source: https://github.com/auth0/auth0-react/blob/e06d8d3ad11041bae850118074b99398db26e701/src/with-authentication-required.tsx

import React, { ComponentType, useEffect, FC } from "react";
import { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { isAuthorized } from "./userUtils";
import { UserRoleEnum } from "../__generated__/global";
import { Redirect } from "react-router-dom";
import {
  isAuthenticatedVar,
  isAuthenticationLoadingVar,
  currentUserVar,
} from "../cache";
import { useReactiveVar } from "@apollo/client";

const defaultOnRedirecting = (): JSX.Element => <Loading />;

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

export interface WithAuthorizationRequiredOptions {
  returnTo?: string | (() => string);
  onRedirecting?: () => JSX.Element;
  loginOptions?: RedirectLoginOptions;
  authorizedRoles?: UserRoleEnum[];
}

const withAuthorizationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthorizationRequiredOptions = {}
): FC<P> => {
  return function WithAuthorizationRequired(props: P): JSX.Element {
    const { loginWithRedirect } = useAuth0();
    const isAuthenticated = useReactiveVar(isAuthenticatedVar);
    const isAuthenticationLoading = useReactiveVar(isAuthenticationLoadingVar);
    const currentUser = useReactiveVar(currentUserVar);

    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
      authorizedRoles = [],
    } = options;

    useEffect(() => {
      if (isAuthenticationLoading || isAuthenticated) {
        return;
      }

      const opts = {
        ...loginOptions,
        appState: {
          ...loginOptions.appState,
          returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
        },
      };
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }, [
      currentUser,
      isAuthenticationLoading,
      isAuthenticated,
      loginWithRedirect,
      loginOptions,
      authorizedRoles,
      returnTo,
    ]);

    if (currentUser) {
      if (isAuthorized(currentUser, authorizedRoles)) {
        return <Component {...props} />;
      } else {
        return <Redirect to="/" />;
      }
    }

    // Return loading until a current user is present...
    return onRedirecting();
  };
};

export default withAuthorizationRequired;
