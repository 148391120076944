import React from "react";
import { Container, Row, Col } from "reactstrap";
import withAuthorizationRequired from "../utils/withAuthorizationRequired";
import { currentUserVar, socialProfilePicVar } from "../cache";
import { useReactiveVar } from "@apollo/client";
import { UserRoleEnum } from "../__generated__/global";

export const ProfileComponent = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const authProfilePic = useReactiveVar(socialProfilePicVar);

  return (
    <div data-testid="profile-view">
      <Container className="mb-5">
        <Row className="align-items-center profile-header mb-5 text-center text-md-left">
          <Col md={2}>
            <img
              src={authProfilePic}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          </Col>
          <Col md>
            <h2>
              {currentUser?.first} {currentUser?.last}
            </h2>
            <p className="lead text-muted">{currentUser?.email}</p>
          </Col>
        </Row>
        <Row>{JSON.stringify(currentUser, null, 2)}</Row>
      </Container>
    </div>
  );
};

export default withAuthorizationRequired(ProfileComponent, {
  authorizedRoles: [UserRoleEnum["ADMIN"], UserRoleEnum["TEACHER"]],
});
