import React, { ChangeEvent } from "react";
import get from "just-safe-get";

import { gql, useQuery } from "@apollo/client";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getUsers as getUsersData,
  getUsers_users_connection_edges as UserEdge,
} from "./__generated__/getUsers";

import styles from "./styles.module.scss";

export const GET_USERS = gql`
  query getUsers($role: UserRoleEnum!) {
    users {
      connection(
        first: 30
        sortBy: [
          { sort: LAST, direction: ASC }
          { sort: EMAIL, direction: DESC }
        ]
        filterBy: { roles: [$role] }
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            first
            last
            email
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

type UserSelectProps = {
  userId: string;
  setUserId: (id: string) => void;
  userType: "STUDENT" | "TEACHER";
  label: string;
  testLabel: string;
};

type getUsersVars = {
  role: "STUDENT" | "TEACHER";
};

export const UserSelect = (props: UserSelectProps) => {
  const { loading, error, data } = useQuery<getUsersData, getUsersVars>(
    GET_USERS,
    {
      variables: { role: props.userType },
    }
  );

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    props.setUserId(event.target.value as string);
  };

  if (loading)
    return (
      <CircularProgress data-testid="user-select-loading" color="primary" />
    );
  if (error) return <div>Error! {error.message}</div>;

  const userEdges: UserEdge[] =
    get(data || {}, ["users", "connection", "edges"]) || [];

  return (
    <FormControl required variant="outlined" className={styles.formControl}>
      <InputLabel id="select-user-label">{props.label}</InputLabel>
      <Select
        labelId="select-user-label"
        id="select-user"
        data-testid={`${props.testLabel}-select`}
        value={props.userId}
        onChange={handleChange}
        label={props.label}
        className={styles.select}
      >
        <MenuItem data-testid="user-select-none" key={0} value="">
          <em>None</em>
        </MenuItem>
        {userEdges.map((userEdge, idx) => {
          let user = userEdge?.node;
          return (
            <MenuItem
              data-testid={`${props.testLabel}-select-option-${idx}`}
              key={user?.id}
              value={user?.id}
            >
              {`${user?.first} ${user?.last}`}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>Required</FormHelperText>
    </FormControl>
  );
};

export default UserSelect;
