import React from "react";
import get from "just-safe-get";

import { gql, useQuery } from "@apollo/client";

import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import {
  getMetricDetails as getMetricDetailsData,
  getMetricDetails_metrics_byId as Metric,
} from "./__generated__/getMetricDetails";

import styles from "./styles.module.scss";

export const GET_METRIC_DETAILS = gql`
  query getMetricDetails($metricId: ID!) {
    metrics {
      byId(id: $metricId) {
        id
        name
        internalName
        description
        level
        totalQuestions
        notesTemplate
        administratorInstructions
        avgFrequency
        minutesToAdminister
      }
    }
  }
`;

type AboutMetricDialogProps = {
  metricId: string;
};

export const AboutMetricDialog = (props: AboutMetricDialogProps) => {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery<getMetricDetailsData>(GET_METRIC_DETAILS, {
    variables: { metricId: props.metricId },
    skip: !props.metricId,
  });

  const metric: Metric = get(data || {}, ["metrics", "byId"]);

  // if (loading) return <CircularProgress color="primary" />;
  // if (error) return <div>Error! {error.message}</div>;
  // if (!metric) return <div />;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="About metric">
        <span>
          <IconButton
            data-testid="about-metric-icon-button"
            aria-label="about"
            disabled={!props.metricId}
            onClick={handleClickOpen}
            className={styles.aboutMetricButton}
          >
            <HelpOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      {metric && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          data-testid="about-metric-dialog"
          open={open}
        >
          <DialogTitle id="simple-dialog-title">
            {metric.internalName}
          </DialogTitle>

          <Divider />

          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <TableBody>
              {metric.internalName !== metric.name && (
                <TableRow>
                  <TableCell>External Name</TableCell>
                  <TableCell>{metric.name}</TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>{metric.description}</TableCell>
              </TableRow>

              {metric.level && (
                <TableRow>
                  <TableCell>Level</TableCell>
                  <TableCell>{metric.level}</TableCell>
                </TableRow>
              )}

              {metric.totalQuestions && (
                <TableRow>
                  <TableCell>Total Questions</TableCell>
                  <TableCell>{metric.totalQuestions}</TableCell>
                </TableRow>
              )}

              {metric.notesTemplate && (
                <TableRow>
                  <TableCell>Total Questions</TableCell>
                  <TableCell>{metric.notesTemplate}</TableCell>
                </TableRow>
              )}

              {metric.administratorInstructions && (
                <TableRow>
                  <TableCell>Administrator Instructions</TableCell>
                  <TableCell>{metric.administratorInstructions}</TableCell>
                </TableRow>
              )}

              {metric.avgFrequency && (
                <TableRow>
                  <TableCell>Average Frequency</TableCell>
                  <TableCell>{metric.avgFrequency}</TableCell>
                </TableRow>
              )}

              {metric.minutesToAdminister && (
                <TableRow>
                  <TableCell>Minutes to Administer</TableCell>
                  <TableCell>{metric.minutesToAdminister} </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default AboutMetricDialog;
