import React, { useState } from "react";
import get from "just-safe-get";

import MUIEditor, {
  MUIEditorState,
  toolbarControlTypes,
  //@ts-ignore
} from "react-mui-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import withAuthorizationRequired from "../utils/withAuthorizationRequired";
import { gql, useApolloClient } from "@apollo/client";

import MetricSelect from "../components/MetricSelect/component";
import AboutMetricDialog from "../components/AboutMetricDialog/component";
import UserSelect from "../components/UserSelect/component";
import MeasurementValuesFields from "../components/MeasurementValuesFields/component";
import MeasurementCreateButton from "../components/MeasurementCreateButton/component";
import Typography from "@material-ui/core/Typography";
import { UserRoleEnum } from "../__generated__/global";

import {
  ReadMetricValues as ReadMetricValuesData,
  ReadMetricValuesVariables as ReadMetricValuesVars,
  ReadMetricValues_metrics_byId_values as MetricValue,
} from "./__generated__/ReadMetricValues";

import styles from "./TestMeasurements.module.scss";

type measurementValuesState = { [key: string]: number };

const TestMeasurements = () => {
  const [metricId, setMetricId] = useState<string>("");
  const [studentId, setStudentId] = useState<string>("");
  const [teacherId, setTeacherId] = useState<string>("");
  const [notes, setNotes] = useState<MUIEditorState>(
    MUIEditorState.createEmpty()
  );
  const [
    measurementValues,
    setMeasurementValues,
  ] = useState<measurementValuesState>({});

  const READ_METRIC_VALUES = gql`
    query ReadMetricValues($id: ID!) {
      metrics {
        byId(id: $id) {
          id
          values {
            id
            slug
            required
          }
        }
      }
    }
  `;

  const selectedMetric = get(
    useApolloClient().readQuery<ReadMetricValuesData, ReadMetricValuesVars>({
      query: READ_METRIC_VALUES,
      variables: {
        id: metricId,
      },
    }) || {},
    ["metrics", "byId"]
  );

  const isValid = () => {
    if (!metricId || !studentId || !teacherId) {
      return false;
    }
    if (!selectedMetric?.values) {
      return false;
    }

    let requiredVals = selectedMetric.values.every((v: MetricValue) => {
      if (!v.required) {
        return true;
      }
      return !!measurementValues[`${v.slug}`];
    });
    if (!requiredVals) {
      return false;
    }

    return true;
  };

  const onEditorChange = (newState: MUIEditorState) => {
    setNotes(newState);
  };

  const resetForm = () => {
    setMetricId("");
    setMeasurementValues({});
    setNotes(EditorState.createEmpty());
  };

  const createInput = () => ({
    metricId,
    studentId,
    teacherId,
    values: Object.keys(measurementValues).map((k) => ({
      key: k,
      value: measurementValues[k],
    })),
    notes: draftToHtml(convertToRaw(notes.getCurrentContent())),
  });

  const editorConfig = {
    editor: {
      wrapperElement: "div",
      className:
        "MuiPaper-outlined MuiPaper-rounded measurement-note-field-editor",
      style: { padding: 20 },
    },
    toolbar: {
      className: "MuiPaper-outlined",
      style: {
        boxShadow: "none",
        padding: 10,
      },
      controls: [
        toolbarControlTypes.bold,
        toolbarControlTypes.italic,
        toolbarControlTypes.underline,
        toolbarControlTypes.fontColor,
        toolbarControlTypes.fontBackgroundColor,
        toolbarControlTypes.divider,
        toolbarControlTypes.textAlign,
        toolbarControlTypes.divider,
        toolbarControlTypes.unorderedList,
        toolbarControlTypes.orderedList,
        toolbarControlTypes.divider,
        toolbarControlTypes.linkAdd,
        toolbarControlTypes.divider,
        toolbarControlTypes.undo,
        toolbarControlTypes.redo,
      ],
    },
  };

  return (
    <div data-testid="test-measurements-view">
      <Typography variant="h3" className={styles.header}>
        Create Test Measurement
      </Typography>
      <div className={styles.formContainer}>
        <UserSelect
          userId={teacherId}
          setUserId={setTeacherId}
          userType="TEACHER"
          label="Teacher"
          testLabel="teacher"
        />
        <UserSelect
          userId={studentId}
          setUserId={setStudentId}
          userType="STUDENT"
          label="Student"
          testLabel="student"
        />
        <div className={styles.metricFieldContainer}>
          <MetricSelect
            metricId={metricId}
            setMetricId={(id: string) => {
              setMetricId(id);
              setMeasurementValues({});
            }}
          />
          <AboutMetricDialog metricId={metricId} />
        </div>
        {metricId && (
          <MeasurementValuesFields
            metricId={metricId}
            measurementValues={measurementValues}
            setMeasurementValues={setMeasurementValues}
          />
        )}
        {metricId && (
          <div>
            <Typography variant="h6" gutterBottom>
              Notes
            </Typography>
            <MUIEditor
              data-testid="measurement-note-field"
              editorState={notes}
              onChange={onEditorChange}
              config={editorConfig}
            />
          </div>
        )}
        <MeasurementCreateButton
          disabled={!isValid()}
          measurementInput={createInput()}
          onSuccess={resetForm}
        />
      </div>
    </div>
  );
};

export default withAuthorizationRequired(TestMeasurements, {
  authorizedRoles: [UserRoleEnum["ADMIN"], UserRoleEnum["TEACHER"]],
});
