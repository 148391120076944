import { getMyUser_users_me as CurrentUser } from "../__generated__/getMyUser";
import { UserRoleEnum } from "../__generated__/global";

export const isAuthorized = (
  user: CurrentUser | undefined | null,
  authorizedRoles: UserRoleEnum[]
) => {
  if (!user) {
    return false;
  }

  return user.roles.some((role) => authorizedRoles.includes(role));
};

export const isAdmin = (user: CurrentUser | undefined | null) => {
  return user && user.roles.includes(UserRoleEnum["ADMIN"]);
};

export const isTeacher = (user: CurrentUser | undefined | null) => {
  return user && user.roles.includes(UserRoleEnum["TEACHER"]);
};
