/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MetricLevelEnum {
  ADVANCED = "ADVANCED",
  BEGINNING = "BEGINNING",
  INTERMEDIATE = "INTERMEDIATE",
}

export enum UserRoleEnum {
  ADMIN = "ADMIN",
  DISABLED = "DISABLED",
  GUARDIAN = "GUARDIAN",
  GUEST = "GUEST",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

/**
 * Autogenerated input type of CreateMeasurement
 */
export interface CreateMeasurementInput {
  clientMutationId?: string | null;
  metricId: string;
  notes?: string | null;
  studentId: string;
  teacherId: string;
  values: MeasurementValueInput[];
}

/**
 * Fields needed to for a MeasurementValue
 */
export interface MeasurementValueInput {
  key: string;
  value: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
